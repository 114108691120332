import { useEffect } from "react";

import { GA } from "../../helpers/GA/ga";
import {useCookiebot} from "../../pages/Cookies";

export function CookieBanner() {
  const {isCookiebotReady, consent} = useCookiebot()

  useEffect(() => {
    if (isCookiebotReady && consent.marketing) {
      new GA().enableGAWindowProperty(window);
    }
  }, [isCookiebotReady, consent]);

  return null;
}

export default CookieBanner;
