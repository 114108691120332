import {useTranslation} from "react-i18next";

export const Footer = () => {
    const {t} = useTranslation("legal");
    return (
        <div className="bg-white rounded-t-xl mt-4 p-1 flex gap-2 justify-center">
            <a href="https://www.easycheck-by-edding.com/impressum/" target="blank"
               className="underline">{t("imprint")}</a>
            <a href="https://www.easycheck-by-edding.com/datenschutzerklaerung/" target="blank"
               className="underline">{t("privacy")}</a>
            <a href="/cookies" target="blank" className="underline">{t("cookies")}</a>
        </div>
    )
}
