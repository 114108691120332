import {useEffect, useState, useCallback, useRef} from 'react';


export const useCookiebot = () => {
    const [isCookiebotReady, setCookiebotReady] = useState(false);
    const [consent, setConsent] = useState({
        necessary: true,
        preferences: false,
        statistics: false,
        marketing: false,
    });

    const updateConsentState = useCallback(() => {
        if ('Cookiebot' in window && typeof window.Cookiebot === 'object') {
            setConsent({
                necessary: true,
                preferences: window.Cookiebot?.consent?.preferences ?? false,
                statistics: window.Cookiebot?.consent?.statistics ?? false,
                marketing: window.Cookiebot?.consent?.marketing ?? false,
            });
        }
    }, []);

    useEffect(() => {
        const handleConsentReady = () => {
            updateConsentState();
            setCookiebotReady(true);
        };

        const handleConsentAccept = () => {
            updateConsentState();
        };

        const handleConsentDecline = () => {
            updateConsentState();
        };

        window.addEventListener('CookiebotOnConsentReady', handleConsentReady);

        window.addEventListener('CookiebotOnAccept', handleConsentAccept);
        window.addEventListener('CookiebotOnDecline', handleConsentDecline);

        return () => {
            window.removeEventListener('CookiebotOnConsentReady', handleConsentReady);
            window.removeEventListener('CookiebotOnAccept', handleConsentAccept);
            window.removeEventListener('CookiebotOnDecline', handleConsentDecline);
        };
    }, [updateConsentState]);

    const showBanner = useCallback(() => {
        if (window.Cookiebot) {
            window.Cookiebot.show();
        }
    }, []);

    const hideBanner = useCallback(() => {
        if (window.Cookiebot) {
            window.Cookiebot.hide();
        }
    }, []);

    const renewConsent = useCallback(() => {
        if (window.Cookiebot) {
            window.Cookiebot.renew();

        }
    }, []);

    const submitCustomConsent = useCallback((optinPreferences: any, optinStatistics: any, optinMarketing: any) => {
        if (window.Cookiebot) {
            window.Cookiebot.submitCustomConsent(optinPreferences, optinStatistics, optinMarketing);
        }
    }, []);

    return {
        isCookiebotReady,
        consent,
        showBanner,
        hideBanner,
        renewConsent,
        submitCustomConsent,
    };
};



export default function Cookies() {
    const { renewConsent } = useCookiebot();
    const cookieboxContent = useRef<HTMLDivElement>(null)

    useEffect(() => {
        const container =cookieboxContent.current;
        const script = document.createElement('script');
        script.id = 'Cookiebot-Dynamic-Content';
        script.src = 'https://consent.cookiebot.com/1babc6e3-cb57-477b-9dde-79eaf255e06a/cd.js';
        script.type = 'text/javascript';
        script.async = true;

        if (container) {
            container.appendChild(script);
        }

        return () => {
            // Clean the container and remove the script
            if (container) {
                container.innerHTML = '';
            }
            const existingScript = document.getElementById('Cookiebot-Dynamic-Content');
            if (existingScript) {
                document.head.removeChild(existingScript);
            }
        };
    }, []);

    return (
        <div className="w-full overflow-hidden">
            <div style={{ height: 48 }} />

            <h1 >Cookieeinstellungen</h1>
            <button

                onClick={() => {
                    renewConsent();
                }}
            >
                Cookies zurücksetzen
            </button>
            <div className="h-10" />
            <div ref={cookieboxContent} id="Cookiebot-Content"/>
        </div>
    );
}
